import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['notAvailable']

  connect() {
    if (this.hasNotAvailableTarget) {
      setTimeout(() => {
        location.reload()
      }, 15000)
    }
  }
}

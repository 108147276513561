import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ['emptySelect']
  static values = {
    emptyCss: { type: String, default: 'select--empty' }
  }
  connect() {
    if (this.element.value == '') {
      this.element.classList.add(this.emptyCssValue)
    }
  }

  valueChange() {
    if (this.element.value == '') {
      this.element.classList.add(this.emptyCssValue)
    } else {
      this.element.classList.remove(this.emptyCssValue)
    }
  }
}

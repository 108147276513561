import { Controller } from "stimulus"
import videojs from 'video.js'

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    this.player = videojs(this.containerTarget, {
      controls: true,
      autoplay: false,
      preload: 'none',
      poster: this.data.get('posterUrl')
    })
    this.player.getChild('bigPlayButton').controlText('Video abspielen');
  }

  disconnect() {
    this.player.dispose()
  }
}
